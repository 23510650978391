import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { useQuery } from "../../hook/useQuery";
import axios from "axios";
import CONFIG from "../../constanta/config";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import useCheckInet from "../../hook/useCheckInet";
import StatusInet from "../../components/status_inet";
import moment from "moment/moment";
import useUser from "../../hook/useUser";
import useReport from "../../hook/useReport";
import PopupSearch from "../../components/popup_search";
import InputSearch from "../../components/input_search";
import InputDOB from "../../components/input_dob";
import PopupAddUser from "../../components/popup_add_user";
import LogoApp from "../../components/logoapp";
import { useForm } from "react-hook-form";
import InputGuest from "../../components/input_guest";
import InputAdd from "../../components/input_add";

const PageCheckin = (props) => {
  const localReport = useReport();
  const checkInet = useCheckInet();
  // const localUser = useUser();
  const query = useQuery();
  const [state, setState] = useState("idle");
  const [showQR, setShowQR] = useState(false);
  const [loading, setLoading] = useState(false);
  const [infoAction, setInfoAction] = useState(false);
  const [user, setUser] = useState(null);
  const [sukses, setSukses] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedDOB, setSelectedDOB] = useState(null);
  const [msgerror, setMsgerror] = useState("");
  const [showAddUser, setShowAddUser] = useState(false);
  const [msgCheckin, setMsgCheckin] = useState("");
  const [count, setCount] = useState(0);
  const [isGuest, setIsGuest] = useState(false);
  const [attendance, setAttendance] = useState("");

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (checkInet.isOnline) {
  //     localReport.fetchData();
  //   }

  //   getReport();
  // }, [checkInet.isOnline]);

  useEffect(() => {
    if (Cookies.get("token") === undefined) {
      navigate("/login-gate");
    }

    if (query.get("id_gate") === null) {
      navigate("/login-gate");
    }

    localReport.fetchData();
  }, []);

  // useEffect(() => {
  //   localReport.fetchData();
  // }, [state]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      setCount(count + 1);
      // localReport.fetchData();
      handleCheckinTime();
    }, 1000 * 5);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [count]);

  const handleCheckinTime = async () => {
    const response = await axios.get(
      CONFIG.URL + "/event/report/currentcheckin"
    );

    let localTime = Cookies.get("local_time");
    if (localTime && localTime !== undefined && localTime !== "undefined") {
      if (moment(response?.data?.last_update) > moment(localTime)) {
        localReport.fetchData();
        Cookies.set("local_time", response?.data?.last_update);
      }
    } else {
      Cookies.set("local_time", response?.data?.last_update);
      localReport.fetchData();
    }
  };

  const handleSuccess = (qr) => {
    // if (checkInet.isOnline) {
    //   checkDataUser(qr);
    // } else {
    //   checkDataUserOffline(qr);
    // }
  };

  const handleFailed = () => {
    setShowQR(false);
    setShowSearch(false);
    setInfoAction(false);
    setUser(null);
    setState("failed");
  };

  const handleCloseScan = () => {
    setIsGuest(false);
    setShowQR(false);
    setShowSearch(false);
    setInfoAction(false);
    setUser(null);
    setState("idle");
  };

  const handleOpenScan = () => {
    setShowQR(true);
    setShowSearch(false);
    setInfoAction(false);
    setUser(null);
    setState("process");
  };

  // OFFLINE MODE
  const checkDataUserOffline = (qr) => {
    setShowQR(false);
    setShowSearch(false);
    setLoading(true);
    let users = window.localStorage.getItem("LocalUser");
    users = JSON.parse(users);

    let data = users.find((v) => v.qr_code === qr);
    if (data) {
      let event = data.event.find((item) => item.gate === query.get("event"));
      if (event) {
        setUser(data);

        if (event.checkin_time === "0000-00-00 00:00:00") {
          setState("success");
        } else {
          // if (event.checkout_time === "0000-00-00 00:00:00") {
          //   setState("exist");
          // } else {
          //   setState("reenter");
          // }

          setState("reenter");
        }
      } else {
        setState("failed");
      }

      setLoading(false);
    } else {
      setLoading(false);
      setState("failed");
    }
  };

  const handleCheckinOffline = () => {
    setSukses(false);
    setState("process");
    setLoading(true);

    let arr = [];
    const payload = {
      qr_code: user?.qr_code,
      id_gate: query.get("id_gate"),
      checkin_time: moment(Date.now()).format("YYYY-MM-DD hh:mm:ss"),
    };

    let checkin = window.localStorage.getItem("LocalCheckin");
    if (checkin) {
      checkin = JSON.parse(checkin);
      arr = [...checkin];

      let exist = arr.find((item) => item.qr_code === payload.qr_code);
      if (!exist) {
        arr.push(payload);

        //update local user
        let users = window.localStorage.getItem("LocalUser");
        users = JSON.parse(users);
        let _users = [...users];
        let _user = _users.find((v) => v.qr_code === payload.qr_code);
        let _event = _user.event.find((v) => v.gate === query.get("event"));
        _event.checkin_time = payload.checkin_time;

        window.localStorage.setItem("LocalUser", JSON.stringify(_users));
        //update local user
      }
    } else {
      arr.push(payload);

      //update local user
      let users = window.localStorage.getItem("LocalUser");
      users = JSON.parse(users);
      let _users = [...users];
      let _user = _users.find((v) => v.qr_code === payload.qr_code);
      let _event = _user.event.find((v) => v.gate === query.get("event"));
      _event.checkin_time = payload.checkin_time;

      window.localStorage.setItem("LocalUser", JSON.stringify(_users));
      //update local user
    }

    window.localStorage.setItem("LocalCheckin", JSON.stringify(arr));

    setInfoAction(true);
    setLoading(false);
    setSukses(true);
  };

  // OFFLINE MODE

  const handleCheckin = () => {
    if (checkInet.isOnline) {
      handleCheckinOnline();
    } else {
      handleCheckinOffline();
    }
  };

  const checkDataUser = () => {
    if (watch("code") !== "") {
      onRentry(watch("code"));
      return;
    } else {
      setState("process");
      setMsgerror("");
      setShowQR(false);
      setShowSearch(false);
      setLoading(true);

      const payload = {
        name: selectedData?.name,
        //birth_date: selectedDOB,
      };

      var form_data = new FormData();

      for (var key in payload) {
        form_data.append(key, payload[key]);
      }

      axios
        .get(CONFIG.URL + "/user/" + selectedData?.id_user, {
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then(function (response) {
          if (response?.data.status === "SUCCESS") {
            let event = response.data?.data[0]?.event_gate[0];

            if (event) {
              setUser(response.data?.data[0]);
              setState("success");
              if (event.checkin_time === "0000-00-00 00:00:00") {
                setMsgCheckin("Allow to Enter");
              } else {
                if (event.checkout_time === "0000-00-00 00:00:00") {
                  setMsgCheckin("Already Checked In");
                } else {
                  if (
                    (moment(event.checkin_time) > moment(event.checkout_time)) || (moment(event.reentry_time) > moment(event.checkout_time))
                  ) {
                    setMsgCheckin("Already Checked In");
                  } else {
                    setMsgCheckin("Allowed to Re-Enter");
                  }
                }
              }
            } else {
              setUser(response.data?.data[0]);
              setState("success");
              setMsgCheckin("Allow to Enter");
            }
          } else {
            setMsgerror("User not found");
          }
          setLoading(false);
        })
        .catch(function (error) {
          handleCloseScan();
          setLoading(false);
        });
    }
  };

  const handleCheckinOnline = () => {
    if (user?.event_gate.length === 0) {
      onAddUser(user.id_user);
    } else {
      setSukses(false);
      setState("process");
      setLoading(true);

      const payload = {
        id_user: user?.id_user,
        id_gate: query.get("id_gate"),
      };

      var form_data = new FormData();

      for (var key in payload) {
        form_data.append(key, payload[key]);
      }

      let url = '/event/checkin';

      if(msgCheckin === "Allowed to Re-Enter")
        url = "/event/reentry";

      axios
        .post(CONFIG.URL + url, form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then(function (response) {
          setInfoAction(true);
          setLoading(false);
          if (response?.data?.status === "SUCCESS") {
            setSukses(true);
            // localUser.fetchData();
            localReport.fetchData();

            if (msgCheckin !== "Allowed to Re-Enter" && !isGuest) {
              openInNewWindow(
                `/printcode?name=${user?.name}&code=${user?.qr_code}`
              );
            }

            setIsGuest(false);
          } else {
            setSukses(false);
          }
        })
        .catch(function (error) {
          setLoading("false");
          setSukses(false);
        });
    }
  };

  const handleActionFailed = () => {
    setState("success");
    setInfoAction(false);
  };

  const getReport = () => {
    if (checkInet.isOnline) {
      try {
        let _event = localReport?.data?.find(
          (v) => v.gate === query.get("event")
        );
        return _event.checkin;
      } catch (error) {
        return "-";
      }
    } else {
      let _localReport = window.localStorage.getItem("LocalReport");
      _localReport = JSON.parse(_localReport);
      try {
        let _event = _localReport?.find((v) => v.gate === query.get("event"));
        return _event.checkin;
      } catch (error) {
        return "-";
      }
    }
  };

  const handleSuccessSearch = (data) => {
    setSelectedData(data);
  };

  const handleSuccessSearchAdd = (data) => {
    setSelectedData(data);
  };

  const handleSuccessGuest = (data) => {
    if (data !== null) {
      setIsGuest(true);
    }

    setSelectedData(data);
  };

  const handleSuccessDOB = (dob) => {
    setSelectedDOB(dob);
  };

  const checkDisabled = () => {
    if (
      selectedData !== null ||
      (watch("code") !== "" && watch("code") !== undefined)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSuccessAdd = (data) => {
    window.location.reload(true);
    // localUser.fetchData();
  };

  // if (localUser?.data === null || localUser?.data?.length === 0) {
  //   return (
  //     <div className="flex flex-col absolute top-0 left-0 w-screen min-h-screen bg-[#000000b3] text-black justify-center items-center font-semibold text-xl">
  //       Loading...
  //     </div>
  //   );
  // }

  const calcCheckin = () => {
    if (localReport?.data) {
      const { checkin_main, checkout_main } = localReport?.data;

      let total = 0;

      total = checkin_main - checkout_main;

      if (total < 0) {
        return 0;
      } else {
        return total;
      }
    }

    return 0;
  };

  const openInNewWindow = (url) => {
    console.log(url);
    const windowFeatures =
      "menubar=no,toolbar=no,location=no,status=no,resizable=yes,scrollbars=yes,width=800,height=600";
    const newWindow = window.open(url, "_blank", windowFeatures);

    if (newWindow) {
      newWindow.onload = () => {
        setTimeout(() => {
          newWindow.print();
          newWindow.onafterprint = () => {
            newWindow.close();
          };
        }, 1000);
      };
    }
  };

  const handleClosePopUp = () => {
    window.location.reload(true);
  };

  const clearAll = () => {
    setSelectedData(null);
    setValue("code", "");
  };

  const onSubmit = (data) => {
    onRentry(data.code);
  };

  const onAddUser = (idUser) => {
    setSukses(false);
    setState("process");
    setLoading(true);

    const payload = {
      id_user: idUser,
      attendance: attendance,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/user/add", form_data, {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        setAttendance("");
        setInfoAction(true);
        setLoading(false);
        if (response?.data?.status === "SUCCESS") {
          setSukses(true);
          // localUser.fetchData();
          localReport.fetchData();

          openInNewWindow(
            `/printcode?name=${response?.data?.name}&code=${response?.data?.qr_code}`
          );
        } else {
          setSukses(false);
        }
      })
      .catch(function (error) {
        setLoading("false");
        setSukses(false);
      });
  };

  const onRentry = (code) => {
    setState("process");
    setMsgerror("");
    setShowQR(false);
    setShowSearch(false);
    setLoading(true);

    const payload = {
      code: code,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/event/qrcode", form_data, {
        headers: {
          token: CONFIG.TOKEN_VOTING,
        },
      })
      .then(function (response) {
        setValue("code", "");
        if (response?.data.status === "SUCCESS") {
          let event = response.data?.data[0]?.event_gate[0];
          setUser(response.data?.data[0]);
          setState("success");
          if (event.checkin_time === "0000-00-00 00:00:00") {
            setMsgCheckin("Allow to Enter");
          } else {
            if (event.checkout_time === "0000-00-00 00:00:00") {
              setMsgCheckin("Already Checked In");
            } else {
               if((moment(event.checkin_time) > moment(event.checkout_time)) || (moment(event.reentry_time) > moment(event.checkout_time))){
                setMsgCheckin("Already Checked In");
              } else {
                setMsgCheckin("Allowed to Re-Enter");
              }
            }
          }
        } else {
          setState("idle");
          setMsgerror("User not found");
        }
        setLoading(false);
      })
      .catch(function (error) {
        setState("idle");
        handleCloseScan();
        setLoading(false);
      });
  };

  const validateRFID = (value) => {
    if (value.length === 10) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {showAddUser && (
        <PopupAddUser
          onClose={() => handleClosePopUp()}
          onSuccess={() => handleSuccessAdd()}
        />
      )}

      {loading && (
        <>
          <div className="flex flex-col absolute top-0 left-0 w-screen min-h-screen bg-[#000000b3] text-black justify-center items-center font-semibold text-xl">
            Loading...
          </div>
        </>
      )}

      {infoAction && (
        <>
          <div className="flex flex-col absolute top-0 left-0 w-screen min-h-screen bg-[#000000b3] text-black justify-center items-center font-semibold text-xl">
            {sukses ? (
              <>
                <span className="text-green-500 font-bold">
                  Check In Success
                </span>
                <button
                  onClick={() => handleCloseScan()}
                  className="mt-5 min-w-[160px] px-10 py-2 bg-green-500 rounded-lg text-black font-bold"
                >
                  OK
                </button>
              </>
            ) : (
              <>
                <span className="text-red-500 font-bold">Check In Failed</span>
                <button
                  onClick={() => handleActionFailed()}
                  className="mt-5 min-w-[160px] px-10 py-2 bg-red-500 rounded-lg text-black font-bold"
                >
                  OK
                </button>
              </>
            )}
          </div>
        </>
      )}

      {showQR && (
        <QRScan
          onClose={() => handleCloseScan()}
          onSuccess={(data) => handleSuccess(data)}
          onFailed={() => handleFailed()}
          gate="CHECK IN"
          event={""}
        />
      )}

      <div
        className="w-full min-h-screen  flex flex-col justify-center items-center bg-gray-200 px-0 py-5"
        style={{
          background: `url('${props?.config?.background}') no-repeat center center fixed`,
          backgroundSize: "cover",
        }}
      >
        <div className="flex flex-col justify-center items-center mx-auto bg-white rounded-lg shadow-lg p-5 max-w-3xl w-full">
          <div className="flex justify-center mb-2">
            <LogoApp />
          </div>

          {!loading && state !== "process" && (
            <div className="flex flex-col justify-center items-center mb-10">
              <div className="text-black font-semibold mb-2">CHECK IN</div>
              <div className="text-black font-semibold text-sm text-center">
                <div className="font-bold text-xl">Currently Checked In</div>
                Trinidad:{" "}
                {(localReport?.data?.checkin_trinidad + localReport?.data?.reentry_trinidad) -
                  localReport?.data?.checkout_trinidad}
                <br />
                Tobago:{" "}
                {(localReport?.data?.checkin_tobago + localReport?.data?.reentry_tobago) -
                  localReport?.data?.checkout_tobago}
                <br />
                Guest:{" "}
                {(localReport?.data?.checkin_guest + localReport?.data?.reentry_guest) -
                  localReport?.data?.checkout_guest}
                <br />
              </div>
            </div>
          )}

          {state === "idle" && (
            <div className="flex flex-col justify-center items-center w-full">
              <div className="grid grid-cols-2 gap-5 w-full">
                <div className="w-full">
                  <InputSearch
                    onSuccess={(data) => handleSuccessSearch(data)}
                    users={[]}
                    data={selectedData}
                    onClear={() => clearAll()}
                  />
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                  <div className="w-full">
                    <div className=" bg-white rounded-md w-full max-w-lg">
                      <div className="flex flex-col">
                        <div className="text-black mb-2 flex w-full justify-between">
                          <span>Reentry:</span>
                          <button
                            type="button"
                            onClick={() => setValue("code", "")}
                            className="bg-red-500 text-white px-2 py-0 text-sm rounded-md"
                          >
                            Clear
                          </button>
                        </div>
                        <input
                          minLength={10}
                          maxLength={10}
                          placeholder="Scan qr code"
                          className="w-full min-w-[160px] px-2 py-2 bg-whiwhiteet border-2 border-black rounded-lg text-black"
                          {...register("code", {
                            required: true,
                            validate: validateRFID,
                          })}
                        />
                      </div>
                      {errors.code && (
                        <span className="text-red-500 text-sm">
                          QR CODE must consist of 10 characters
                        </span>
                      )}{" "}
                      
                      <button
                        type="submit"
                        className="h-0 w-full bg-blue-500 text-white rounded-md shadow transition duration-300"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>

                <div className="w-full">
                  <InputAdd
                    onSuccess={(data) => handleSuccessSearchAdd(data)}
                    users={[]}
                    data={selectedData}
                    onClear={() => clearAll()}
                  />
                </div>

                <div className="w-full">
                  <InputGuest
                    onSuccess={(data) => handleSuccessGuest(data)}
                    users={[]}
                    data={selectedData}
                    onClear={() => clearAll()}
                  />
                </div>
              </div>

              {msgerror !== "" && (
                <div className="w-full max-w-lg text-center py-2 px-2 rounded-md my-2  text-red-500 font-bold">
                  {msgerror}
                </div>
              )}

              <div className="flex space-x-2 mt-2">
                <button
                  disabled={checkDisabled()}
                  onClick={() => checkDataUser()}
                  className={`${
                    checkDisabled() ? "bg-gray-500" : "bg-green-500"
                  }  min-w-[160px] px-10 py-2  rounded-lg text-white font-bold`}
                >
                  SUBMIT
                </button>

                {/* <button
                  onClick={() => setShowAddUser(true)}
                  className="min-w-[160px]  px-10 py-2 bg-blue-500 rounded-lg text-white font-bold"
                >
                  ADD USER
                </button> */}
              </div>
            </div>
          )}

          {state === "success" && (
            <div className="flex flex-col justify-center items-center pb-0 w-full">
              <div
                className={`${
                  msgCheckin === "Already Checked In"
                    ? "bg-red-500"
                    : "bg-green-500"
                } rounded-lg text-white font-bold mb-6 text-xl  px-10 py-5 w-full max-w-lg text-center`}
              >
                <>
                  {msgCheckin === "Already Checked In" ? (
                    <>
                      {msgCheckin}: {user?.event_gate[0]?.checkin_time}
                    </>
                  ) : (
                    <>{msgCheckin}</>
                  )}
                </>
              </div>

              <div className="text-black flex flex-col font-bold mb-10 mt-8 text-2xl text-center">
                <span className="text-4xl"> {user?.name}</span>
                <span className="text-lg text-black mt-2">
                  {`${
                    user?.dob !== "0000-00-00"
                      ? "(" + moment(user?.dob).format("DD/MM/YYYY") + ")"
                      : ""
                  }`}
                </span>
                <span className={`text-2xl uppercase font-bold ${user?.status === "In Good Standing" ? 'text-green-500' : 'text-red-500'}`}>{user?.status}</span>

                {msgCheckin === "Allow to Enter" &&
                  user?.event_gate.length === 0 && (
                    <div className="flex flex-col gap-2 justify-center items-center mt-4">
                      <button
                        onClick={() => setAttendance("Trinidad")}
                        className={`${
                          attendance === "Trinidad"
                            ? "bg-gray-500"
                            : "bg-orange-500"
                        } w-[200px]  px-10 py-2 text-base   rounded-lg text-white font-bold`}
                      >
                        TRINIDAD
                      </button>

                      <button
                        onClick={() => setAttendance("Tobago")}
                        className={`${
                          attendance === "Tobago"
                            ? "bg-gray-500"
                            : "bg-orange-500"
                        } w-[200px]  px-10 py-2 text-base  rounded-lg text-white font-bold`}
                      >
                        TOBAGO
                      </button>
                    </div>
                  )}
              </div>

              <div className="flex space-x-2">
                {msgCheckin !== "Already Checked In" && (
                  <button
                    onClick={() => handleCheckinOnline()}
                    className="min-w-[160px]  px-10 py-2 bg-green-500 rounded-lg text-white font-bold"
                  >
                     {msgCheckin !== "Allowed to Re-Enter" ? 'CHECK IN' : 'REENTRY'  }
                  </button>
                )}

                <button
                  onClick={() => handleCloseScan()}
                  className="min-w-[160px] px-10 py-2 bg-red-500 rounded-lg text-white font-bold"
                >
                  CANCEL
                </button>

                {(msgCheckin !== "Allowed to Re-Enter" &&
                  msgCheckin !== "Allow to Enter") &&
                  !isGuest && (
                    <button
                      onClick={() =>
                        openInNewWindow(
                          `/printcode?name=${user?.name}&code=${user?.qr_code}`
                        )
                      }
                      className="min-w-[160px] px-10 py-2 bg-purple-500 rounded-lg text-white font-bold"
                    >
                      REPRINT
                    </button>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PageCheckin;
