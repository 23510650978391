import axios from "axios";
import { useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import CONFIG from "../../constanta/config";
import LogoApp from "../../components/logoapp";

const FormRegister = (props) => {
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      question: [{ question: "" }], // Start with one empty question field
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "question",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertOepn, setIsAlertOpen] = useState(false);
  const [formData, setFormData] = useState(null);

  const onSubmit = (data) => {
    setMsg("");
    setFormData(data); // Store the form data
    setIsModalOpen(true); // Open the modal
  };

  const confirmSubmission = () => {
    setLoading(true);
    setMsg("");
    const payload = {
      ...formData,
      attendance: props.attendance,
      question:
        formData.question[0].question === ""
          ? ""
          : JSON.stringify(formData.question),
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/user/register", form_data)
      .then(function (response) {
        setLoading(false);
        setIsModalOpen(false);
        if (response?.data.status === "SUCCESS") {
          // Close the modal after submission

          props.handleChangeState("success");
        } else {
          setMsg("Error: " + response.data?.message);
        }
      })
      .catch(function (error) {
        setMsg("Error: " + error?.message);
        setLoading(false);
        setIsModalOpen(false);
      });

    //console.log(formData); // You can handle the final submission here
  };

  const confirmSubmissionVirtual = () => {
    setLoading(true);
    setMsg("");
    const payload = {
      ...formData,
      attendance: props.attendance,
      question:
        formData.question[0].question === ""
          ? ""
          : JSON.stringify(formData.question),
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/user/register", form_data)
      .then(function (response) {
        setLoading(false);
        setIsModalOpen(false);
        if (response?.data.status === "SUCCESS") {
          // Close the modal after submission

          if (props.attendance === "Virtual") {
            // link zoom event pas gagal
            // window.location.href =
            //   "https://us02web.zoom.us/webinar/register/WN_kxsSFXrqQACVJEahdjJdSg#/registration";

              window.location.href =
              "https://us02web.zoom.us/webinar/register/WN_u-e6nV1lRrerLyV59mnITg";
          } else {
            props.handleChangeState("success");
          }
        } else {
          setMsg("Error: " + response.data?.message);
        }
      })
      .catch(function (error) {
        setMsg("Error: " + error?.message);
        setLoading(false);
        setIsModalOpen(false);
      });

    //console.log(formData); // You can handle the final submission here
  };

  const email = watch("email");

  return (
    <>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto">
            <h2 className="text-xl font-semibold mb-4 text-center">
              Confirm Your Registration
            </h2>

            <div className="mb-4">
              {props.attendance === "Trinidad" && (
                <div className=" flex flex-col justify-start items-center bg-gray-200 shadow-md text-black p-2 rounded-lg shadow  transition duration-300">
                  <h3 className="text-lg font-bold">TRINIDAD</h3>
                  <p className="text-sm mt-2  text-center">
                  Trinidad Hilton & Conference Centre (The Grand Ballroom), located at Lady Young Road, Belmont.
                  </p>
                </div>
              )}

              {props.attendance === "Tobago" && (
                <div className="flex flex-col justify-start items-center bg-gray-200 shadow-md text-black p-2 px-4 rounded-lg shadow  transition duration-300">
                  <h3 className="text-lg font-bold">TOBAGO</h3>
                  <p className="text-sm mt-2  text-center">
                  Scarborough Public Library, located at 11-13 Gardenside Street, Scarborough.
                  </p>
                </div>
              )}

              {props.attendance === "Virtual" && (
                <div className="flex flex-col justify-start items-center bg-gray-200 shadow-md text-black p-2 px-4 rounded-lg shadow transition duration-300">
                  <h3 className="text-lg font-bold text-center">VIRTUAL</h3>
                  <p className="text-sm mt-2  text-center">
                    Zoom
                    <br />
                    Video Conferencing
                  </p>
                  <strong className="text-sm">
                    All Virtual Attendees must complete their Registration via
                    Zoom
                  </strong>
                </div>
              )}
            </div>
            <p>
              <strong>First Name:</strong> {formData.firstname}
            </p>
            <p>
              <strong>Last Name:</strong> {formData.lastname}
            </p>
            <p>
              <strong>Date of Birth:</strong> {formData.dob}
            </p>
            <p>
              <strong>Email:</strong> {formData.email}
            </p>
            <p>
              <strong>Email Confirmation:</strong> {formData.emailConfirmation}
            </p>
            <div className="mt-4">
              <strong>Questions:</strong>
              {formData.question.map((item, index) => (
                <p key={index}>{item.question}</p>
              ))}
            </div>

            <div className="mt-6 flex justify-between">
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-500 text-white py-2 px-4 rounded-md  transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (props.attendance === "Virtual") {
                    setIsModalOpen(false);
                    setIsAlertOpen(true);
                  } else {
                    confirmSubmission()
                  }
                }}
                className="bg-blue-500 text-white py-2 px-4 rounded-md shadow  transition duration-300"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {isAlertOepn && props.attendance === "Virtual" && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg max-w-md mx-auto">
            <div className="bg-[#92182f] text-center px-6 py-10 max-w-md mx-auto rounded-md">
              <p className="text-white text-lg font-semibold">
                Important Note:
              </p>
              <p className="text-white text-xl font-bold mt-2">
                YOUR AGM REGISTRATION IS NOT YET COMPLETE!
              </p>
              <p className="text-white mt-4">
                Virtual Attendees must also register via ZOOM to view the
                meeting virtually.
              </p>
              <button onClick={() => confirmSubmissionVirtual()} className="bg-white text-red-800 font-semibold px-6 py-2 mt-6 rounded-md">
                CONTINUE TO ZOOM REGISTRATION
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col justify-start items-start w-full md:w-1/2">
        <div className="flex flex-col justify-start items-start space-y-2.5 w-full">
          <div className="mx-auto bg-white rounded-lg shadow-lg p-5 max-w-3xl w-full">
            <div className="flex justify-center mb-2">
              <LogoApp />
            </div>

            <div className="mb-4">
              {props.attendance === "Trinidad" && (
                <div className=" flex flex-col justify-start items-center bg-gray-200 shadow-md text-black p-2 rounded-lg shadow  transition duration-300">
                  <h3 className="text-lg font-bold">TRINIDAD</h3>
                  <p className="text-sm mt-2  text-center">
                  Trinidad Hilton & Conference Centre (The Grand Ballroom), located at Lady Young Road, Belmont.
                  </p>
                </div>
              )}

              {props.attendance === "Tobago" && (
                <div className="flex flex-col justify-start items-center bg-gray-200 shadow-md text-black p-2 px-4 rounded-lg shadow  transition duration-300">
                  <h3 className="text-lg font-bold">TOBAGO</h3>
                  <p className="text-sm mt-2  text-center">
                  Scarborough Public Library, located at 11-13 Gardenside Street, Scarborough.
                  </p>
                </div>
              )}

              {props.attendance === "Virtual" && (
                <div className="flex flex-col justify-start items-center bg-gray-200 shadow-md text-black p-2 px-4 rounded-lg shadow transition duration-300">
                  <h3 className="text-lg font-bold text-center">VIRTUAL</h3>
                  <p className="text-sm mt-2  text-center">
                    Zoom
                    <br />
                    Video Conferencing
                  </p>
                  <strong className="text-sm">
                    All Virtual Attendees must complete their Registration via
                    Zoom
                  </strong>
                </div>
              )}
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
              <div className="mb-4">
                <label
                  htmlFor="firstname"
                  className="block text-sm font-medium text-gray-700"
                >
                  First Name
                </label>
                <input
                  id="firstname"
                  name="firstname"
                  type="text"
                  {...register("firstname", {
                    required: "First Name is required",
                  })}
                  className={`mt-1 block w-full p-2 border ${
                    errors.firstname ? "border-red-500" : "border-gray-300"
                  } rounded-md shadow-sm`}
                />
                {errors.firstname && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.firstname.message}
                  </p>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="lastname"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last Name
                </label>
                <input
                  id="lastname"
                  name="lastname"
                  type="text"
                  {...register("lastname", {
                    required: "Last Name is required",
                  })}
                  className={`mt-1 block w-full p-2 border ${
                    errors.lastname ? "border-red-500" : "border-gray-300"
                  } rounded-md shadow-sm`}
                />
                {errors.lastname && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.lastname.message}
                  </p>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="dob"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date of Birth
                </label>
                <input
                  id="dob"
                  name="dob"
                  type="date"
                  {...register("dob", {
                    required: "Date of Birth is required",
                  })}
                  className={`mt-1 block w-full p-2 border ${
                    errors.dob ? "border-red-500" : "border-gray-300"
                  } rounded-md shadow-sm`}
                />
                {errors.dob && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.dob.message}
                  </p>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="text"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: "Invalid email address",
                    },
                  })}
                  className={`mt-1 block w-full p-2 border ${
                    errors.email ? "border-red-500" : "border-gray-300"
                  } rounded-md shadow-sm`}
                />
                {errors.email && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.email.message}
                  </p>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="emailConfirmation"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Confirmation
                </label>
                <input
                  id="emailConfirmation"
                  name="emailConfirmation"
                  type="text"
                  {...register("emailConfirmation", {
                    required: "Email Confirmation is required",
                    validate: (value) =>
                      value === email || "Emails do not match",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: "Invalid email address",
                    },
                  })}
                  className={`mt-1 block w-full p-2 border ${
                    errors.emailConfirmation
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md shadow-sm`}
                />
                {errors.emailConfirmation && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.emailConfirmation.message}
                  </p>
                )}
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Submit Questions
                </label>
                {fields.map((item, index) => (
                  <div key={item.id} className="flex items-start mb-2">
                    <div className="mr-1">{index + 1}.</div>
                    <textarea
                      {...register(`question.${index}.question`)}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    />
                    {fields.length > 1 && index !== 0 && (
                      <button
                        type="button"
                        onClick={() => remove(index)}
                        className="ml-2 text-sm bg-red-500 text-white px-2 py-1 rounded-md shadow-sm  transition duration-300"
                      >
                        Delete
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => append({ question: "" })}
                  className="mt-2 w-full bg-green-500 text-white px-4 py-2 rounded-md shadow-sm  transition duration-300"
                >
                  Add Question
                </button>
                {errors.question && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.question.message}
                  </p>
                )}
              </div>

              {msg !== "" && (
                <div className="text-center font-bold text-red-500 mb-2">
                  {msg}
                </div>
              )}

              <div className="flex space-x-4">
                <button
                  onClick={() => props.handleChangeState("menu")}
                  type="button"
                  className="w-full bg-red-500 text-white py-2 px-4 rounded-md shadow transition duration-300"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="w-full bg-blue-500 text-white py-2 px-4 rounded-md shadow transition duration-300"
                >
                  {props.attendance === "Virtual"
                    ? "Continue Registration"
                    : "Submit Registration"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormRegister;
