import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import LogoApp from "../../components/logoapp";

const MenuRegister = (props) => {
  const [report, setReport] = useState([]);
  const [isLimit, setisLimit] = useState(false);

  useEffect(() => {
    getStat();
  }, []);

  const getStat = () => {
    axios
      .get(CONFIG.URL + "/user/registrationStat", {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          setReport(response.data?.data);
        } else {
        }
      })
      .catch(function (error) {});
  };

  const getTotal = (name, target) => {
    let item = report.find((e) => e.registration_stat === name);

    if(item){
      if (parseInt(item.total) < parseInt(target)) {
        setisLimit(false);
        return true;
      } else {
        setisLimit(true);
        return false;
      }
    }
    else{
      setisLimit(false);
      return true;
    }

    
  };

  return (
    <div className="flex flex-col justify-start items-start w-full md:w-1/2">
      <div className="flex flex-col justify-start items-start space-y-2.5 w-full">
        <div className="mx-auto bg-white rounded-lg shadow-lg p-5 max-w-3xl w-full">
          <div className="flex justify-center mb-2">
            <LogoApp />
          </div>
          <h1 className="text-center text-2xl font-bold text-gray-800 mb-2">
            WORKS CREDIT UNION
          </h1>
          <h2 className="text-center text-2xl text-gray-600 mb-8">
            Reconvened Annual General Meeting 2024
          </h2>
          <p className="text-center text-lg text-gray-600 mb-8">
            Please begin your registration by selecting your preferred
            attendance option:
          </p>

          {isLimit && (
            <div className="flex flex-col text-center mb-5 font-bold text-[#92182f]">
              All the seats for your selected option have been filled.
              <br />
              You can select Virtual Attendance to participate in the AGM.
            </div>
          )}
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 ">
            <button
              onClick={() => {
                if (getTotal("Trinidad", props.config?.limit_trinidad)) {
                  props.handleChooseAttendance("register", "Trinidad");
                }
              
              }}
              className=" flex flex-col justify-start items-center bg-[#92182f] text-white py-6 px-4 rounded-lg shadow hover:bg-red-700 transition duration-300"
            >
              <h3 className="text-lg font-bold">TRINIDAD</h3>
              <p className="text-sm mt-2">
              Trinidad Hilton & Conference Centre (The Grand Ballroom), located at Lady Young Road, Belmont.
              </p>
            </button>
            <button
              onClick={() => {
                if (getTotal("Tobago", props.config?.limit_tobago)) {
                  props.handleChooseAttendance("register", "Tobago");
                }
              }}
              className="flex flex-col justify-start items-center bg-[#92182f] text-white py-6 px-4 rounded-lg shadow hover:bg-red-700 transition duration-300"
            >
              <h3 className="text-lg font-bold">TOBAGO</h3>
              <p className="text-sm mt-2">
              Scarborough Public Library, located at 11-13 Gardenside Street, Scarborough.
              </p>
            </button>
            <button
              onClick={() => {
                props.handleChooseAttendance("register", "Virtual");
              }}
              className="flex flex-col justify-start items-center bg-[#92182f] text-white py-6 px-4 rounded-lg shadow hover:bg-red-700 transition duration-300"
            >
              <h3 className="text-lg font-bold">VIRTUAL</h3>
              <p className="text-sm mt-2">
                Zoom
                <br />
                Video Conferencing
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuRegister;
