import { useEffect } from "react";
import LogoApp from "../../components/logoapp";

const FormSuccess = (props) => {
  // useEffect(() => {
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 2000);
  // }, []);

  return (
    <div className="flex flex-col justify-start items-start w-full md:w-1/2">
      <div className="flex flex-col justify-start items-start space-y-2.5 w-full">
        <div className="mx-auto bg-white rounded-lg shadow-lg p-5 max-w-3xl w-full">
          <div className="flex justify-center mb-2">
            <LogoApp />
          </div>
          <div>
            <div className="mx-auto  text-center pb-6 rounded-lg  max-w-md w-full">
              <h2 className="text-2xl font-semibold mb-5">Congratulations!</h2>
              <p className="mb-4">
                Your ‘{props.attendance !== "Virtual" ? "In Person" : "Virtual"}
                ’ registration for Works AGM 2024 has been successfully
                submitted for Approval.
              </p>
              <p className="mb-4">
                Once your registration has been approved, a confirmation email
                will be sent to you with additional details to access the AGM
                venue.
              </p>
              <p className="mb-4">
                Please check your Spam or Promotions email folders if you do not
                see the email in your Primary inbox.
              </p>
              <p className="font-semibold">
                We look forward to another successful AGM!
              </p>
            </div>

            {/* <div className="w-full flex justify-center items-center mt-5">
              <button
                type="button"
                onClick={() => {
                  window.location.reload();
                }}
                className={`text-white py-2 rounded-lg   font-bold bg-green-500  w-full md:max-w-[160px] text-center uppercase`}
              >
                OK
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSuccess;
