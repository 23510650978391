import { useEffect, useState } from "react";
import axios from "axios";
import CONFIG from "../constanta/config";

const LogoApp = () => {
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getConfig();
  }, []);

  const getConfig = () => {
    setLoading(true);
    axios
      .get(CONFIG.URL + "/config?rnd=" + Date.now(), {})
      .then(function (response) {
        if (response.data.status === "SUCCESS") {
          setConfig(response.data.config);
        } else {
        }
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoading(false);
      });
  };

  return <img src={config?.event_info} className="object-contain w-24 h-auto mb-2 rounded-md" alt="" />;
};

export default LogoApp;
