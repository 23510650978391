import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import MenuRegister from "./menuRegister";
import FormRegister from "./formRegister";
import FormSuccess from "./formSuccess";
import LogoApp from "../../components/logoapp";

const PageRegister = (props) => {
  const [state, setState] = useState("menu");
  const [attendance, setAttendance] = useState("");

  const handleChangeState = (val) => {
    setState(val);
  };

  const handleChooseAttendance = (st, attend) => {
    handleChangeState(st);
    setAttendance(attend);
  };

  if (props?.config?.registration_end === "disable") {
    return (
      <div
        className="w-full min-h-screen h-full flex flex-col justify-start md:justify-center items-center bg-gray-200 p-5"
        style={{
          background: `url('${props?.config?.background}') no-repeat center center fixed`,
          backgroundSize: "cover",
        }}
      >
        <div className="flex flex-col justify-start items-start w-full md:w-1/2">
          <div className="flex flex-col justify-start items-start space-y-2.5 w-full">
            <div className="mx-auto bg-white rounded-lg shadow-lg p-5 max-w-3xl w-full">
              <div className="flex justify-center mb-2">
                <LogoApp />
              </div>
              <h1 className="text-center text-2xl font-bold text-gray-800 mb-2">
                WORKS CREDIT UNION
              </h1>
              <h2 className="text-center text-2xl text-gray-600 mb-8">
                Reconvened Annual General Meeting 2024
              </h2>
              <p className="text-center text-lg text-white bg-red-500 rounded-lg p-2 font-bold mb-8">
                {props.config.text_info}
              </p>

              
              
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className="w-full min-h-screen h-full flex flex-col justify-start md:justify-center items-center bg-gray-200 p-5"
        style={{
          background: `url('${props?.config?.background}') no-repeat center center fixed`,
          backgroundSize: "cover",
        }}
      >
        {state === "menu" && (
          <MenuRegister
            config={props?.config}
            handleChooseAttendance={(st, attend) =>
              handleChooseAttendance(st, attend)
            }
          />
        )}
        {state === "register" && (
          <FormRegister
            attendance={attendance}
            handleChangeState={(val) => handleChangeState(val)}
          />
        )}
        {state === "success" && (
          <FormSuccess
            attendance={attendance}
            handleChangeState={(val) => handleChangeState(val)}
          />
        )}
      </div>
    </>
  );
};

export default PageRegister;
