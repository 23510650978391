import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { useQuery } from "../../hook/useQuery";
import axios from "axios";
import CONFIG from "../../constanta/config";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import useCheckInet from "../../hook/useCheckInet";
import StatusInet from "../../components/status_inet";
import moment from "moment/moment";
import useUser from "../../hook/useUser";
import useReport from "../../hook/useReport";
import PopupSearch from "../../components/popup_search";
import InputSearch from "../../components/input_search";
import InputDOB from "../../components/input_dob";
import PopupAddUser from "../../components/popup_add_user";
import LogoApp from "../../components/logoapp";
import { useForm } from "react-hook-form";
import InputCheckout from "../../components/input_checkout";

const PageCheckout = (props) => {
  const localReport = useReport();
  const checkInet = useCheckInet();
  // const localUser = useUser();
  const query = useQuery();
  const [state, setState] = useState("idle");
  const [showQR, setShowQR] = useState(false);
  const [loading, setLoading] = useState(false);
  const [infoAction, setInfoAction] = useState(false);
  const [user, setUser] = useState(null);
  const [sukses, setSukses] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedDOB, setSelectedDOB] = useState(null);
  const [msgerror, setMsgerror] = useState("");
  const [showAddUser, setShowAddUser] = useState(false);
  const [msgCheckin, setMsgCheckin] = useState("");

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  // useEffect(() => {
  //   if (checkInet.isOnline) {
  //     localReport.fetchData();
  //   }

  //   getReport();
  // }, [checkInet.isOnline]);

  useEffect(() => {
    if (msgCheckin === "Allowed to Checkout") {
      setTimeout(() => {
        handleCheckinOnline();
      }, 1000);
    }
  }, [msgCheckin]);

  useEffect(() => {
    if (Cookies.get("token") === undefined) {
      navigate("/login-gate");
    }

    if (query.get("id_gate") === null) {
      navigate("/login-gate");
    }

    // localUser.fetchData();
  }, []);

  const handleSuccess = (qr) => {
    // if (checkInet.isOnline) {
    //   checkDataUser(qr);
    // } else {
    //   checkDataUserOffline(qr);
    // }
  };

  const handleFailed = () => {
    setShowQR(false);
    setShowSearch(false);
    setInfoAction(false);
    setUser(null);
    setState("failed");
  };

  const handleCloseScan = () => {
    setShowQR(false);
    setShowSearch(false);
    setInfoAction(false);
    setUser(null);
    setState("idle");
  };

  const handleOpenScan = () => {
    setShowQR(true);
    setShowSearch(false);
    setInfoAction(false);
    setUser(null);
    setState("process");
  };

  // OFFLINE MODE
  const checkDataUserOffline = (qr) => {
    setShowQR(false);
    setShowSearch(false);
    setLoading(true);
    let users = window.localStorage.getItem("LocalUser");
    users = JSON.parse(users);

    let data = users.find((v) => v.qr_code === qr);
    if (data) {
      let event = data.event.find((item) => item.gate === query.get("event"));
      if (event) {
        setUser(data);

        if (event.checkin_time === "0000-00-00 00:00:00") {
          setState("success");
        } else {
          // if (event.checkout_time === "0000-00-00 00:00:00") {
          //   setState("exist");
          // } else {
          //   setState("reenter");
          // }

          setState("reenter");
        }
      } else {
        setState("failed");
      }

      setLoading(false);
    } else {
      setLoading(false);
      setState("failed");
    }
  };

  const handleCheckinOffline = () => {
    setSukses(false);
    setState("process");
    setLoading(true);

    let arr = [];
    const payload = {
      qr_code: user?.qr_code,
      id_gate: query.get("id_gate"),
      checkin_time: moment(Date.now()).format("YYYY-MM-DD hh:mm:ss"),
    };

    let checkin = window.localStorage.getItem("LocalCheckin");
    if (checkin) {
      checkin = JSON.parse(checkin);
      arr = [...checkin];

      let exist = arr.find((item) => item.qr_code === payload.qr_code);
      if (!exist) {
        arr.push(payload);

        //update local user
        let users = window.localStorage.getItem("LocalUser");
        users = JSON.parse(users);
        let _users = [...users];
        let _user = _users.find((v) => v.qr_code === payload.qr_code);
        let _event = _user.event.find((v) => v.gate === query.get("event"));
        _event.checkin_time = payload.checkin_time;

        window.localStorage.setItem("LocalUser", JSON.stringify(_users));
        //update local user
      }
    } else {
      arr.push(payload);

      //update local user
      let users = window.localStorage.getItem("LocalUser");
      users = JSON.parse(users);
      let _users = [...users];
      let _user = _users.find((v) => v.qr_code === payload.qr_code);
      let _event = _user.event.find((v) => v.gate === query.get("event"));
      _event.checkin_time = payload.checkin_time;

      window.localStorage.setItem("LocalUser", JSON.stringify(_users));
      //update local user
    }

    window.localStorage.setItem("LocalCheckin", JSON.stringify(arr));

    setInfoAction(true);
    setLoading(false);
    setSukses(true);
  };

  // OFFLINE MODE

  const handleCheckin = () => {
    if (checkInet.isOnline) {
      handleCheckinOnline();
    } else {
      handleCheckinOffline();
    }
  };

  const onRentry = (code) => {
    setState("process");
    setMsgerror("");
    setShowQR(false);
    setShowSearch(false);
    setLoading(true);

    const payload = {
      code: code,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/event/qrcode", form_data, {
        headers: {
          token: CONFIG.TOKEN_VOTING,
        },
      })
      .then(function (response) {
        setValue("code", "");
        if (response?.data.status === "SUCCESS") {
          let event = response.data?.data[0]?.event_gate[0];

          if (event) {
            setUser(response.data?.data[0]);
            setState("success");


            if (event.checkout_time === "0000-00-00 00:00:00") {
              if (event.checkin_time !== "0000-00-00 00:00:00") {
                setMsgCheckin("Allowed to Checkout");
              } else {
                setMsgCheckin("Haven't Checked in");
              }
            } else {
              if(moment(event.reentry_time) > moment(event.checkout_time)){
                setMsgCheckin("Allowed to Checkout");
              }
              else if ((moment(event.checkin_time) < moment(event.checkout_time)) || (moment(event.reentry_time) > moment(event.checkout_time))) {
                setMsgCheckin("Already Checked Out");
              } else{
                setMsgCheckin("Already Checked Out");
              }
            }
          }
        } else {
          setState("idle");
          setMsgerror("User not found");
        }
        setLoading(false);
      })
      .catch(function (error) {
        setValue("code", "");
        setState("idle");
        handleCloseScan();
        setLoading(false);
      });
  };

  const checkDataUser = () => {
    if (watch("code") !== "") {
      onRentry(watch("code"));
      return;
    } else {
      setState("process");
      setMsgerror("");
      setShowQR(false);
      setShowSearch(false);
      setLoading(true);

      const payload = {
        name: selectedData?.name,
        birth_date: selectedDOB,
      };

      var form_data = new FormData();

      for (var key in payload) {
        form_data.append(key, payload[key]);
      }

      axios
        .get(CONFIG.URL + "/user/" + selectedData?.id_user, {
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then(function (response) {
          setSelectedData(null);

          if (response?.data.status === "SUCCESS") {
            let event = response.data?.data[0]?.event_gate[0];
            setUser(response.data?.data[0]);
            setState("success");

            if (event.checkout_time === "0000-00-00 00:00:00") {
              if (event.checkin_time !== "0000-00-00 00:00:00") {
                setMsgCheckin("Allowed to Checkout");
              } else {
                setMsgCheckin("Haven't Checked in");
              }
            } else {
              if ((moment(event.checkin_time) < moment(event.checkout_time)) || (moment(event.reentry_time) > moment(event.checkout_time))) {
                setMsgCheckin("Already Checked Out");
              } else {
                setMsgCheckin("Allowed to Checkout");
              }
            }
          } else {
            setMsgerror("User not found");
          }
          setLoading(false);
        })
        .catch(function (error) {
          setSelectedData(null);
          handleCloseScan();
          setLoading(false);
        });
    }
  };

  const handleCheckinOnline = () => {
    setSukses(false);
    setState("process");
    setLoading(true);

    const payload = {
      id_user: user?.id_user,
      id_gate: query.get("id_gate"),
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/event/checkout", form_data, {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        setInfoAction(true);
        setLoading(false);
        if (response?.data?.status === "SUCCESS") {
          setSukses(true);
          // localUser.fetchData();
          localReport.fetchData();

          setTimeout(() => {
            handleCloseScan();
          }, 500);
        } else {
          setSukses(false);
        }
      })
      .catch(function (error) {
        setLoading("false");
        setSukses(false);
      });
  };

  const handleActionFailed = () => {
    setState("success");
    setInfoAction(false);
  };

  const getReport = () => {
    if (checkInet.isOnline) {
      try {
        let _event = localReport?.data?.find(
          (v) => v.gate === query.get("event")
        );
        return _event.checkin;
      } catch (error) {
        return "-";
      }
    } else {
      let _localReport = window.localStorage.getItem("LocalReport");
      _localReport = JSON.parse(_localReport);
      try {
        let _event = _localReport?.find((v) => v.gate === query.get("event"));
        return _event.checkin;
      } catch (error) {
        return "-";
      }
    }
  };

  const handleSuccessSearch = (data) => {
    setSelectedData(data);
  };

  const handleSuccessDOB = (dob) => {
    setSelectedDOB(dob);
  };

  const checkDisabled = () => {
    if (
      selectedData !== null ||
      (watch("code") !== "" && watch("code") !== undefined)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSuccessAdd = (data) => {
    setShowAddUser(false);
    // localUser.fetchData();
  };

  // if (localUser?.data === null || localUser?.data?.length === 0) {
  //   return (
  //     <div className="flex flex-col absolute top-0 left-0 w-screen min-h-screen bg-[#000000b3] text-white justify-center items-center font-semibold text-xl">
  //       Loading...
  //     </div>
  //   );
  // }

  const clearAll = () => {
    setSelectedData(null);
    setValue("code", "");
  };

  const onSubmit = (data) => {
    onRentry(data.code);
  };
  
  const validateRFID = (value) => {
    if (value.length === 10) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {/* {showAddUser && (
        <PopupAddUser
          onClose={() => setShowAddUser(false)}
          onSuccess={() => handleSuccessAdd()}
        />
      )} */}

      {loading && (
        <>
          <div className="flex flex-col absolute top-0 left-0 w-screen min-h-screen bg-[#000000b3] text-white justify-center items-center font-semibold text-xl">
            Loading...
          </div>
        </>
      )}

      {infoAction && (
        <>
          <div className="flex flex-col absolute top-0 left-0 w-screen min-h-screen bg-[#000000b3] text-white justify-center items-center font-semibold text-xl">
            {sukses ? (
              <>
                <span className="text-green-500 font-bold">
                  Check Out Success
                </span>
                {/* <button
                  onClick={() => handleCloseScan()}
                  className="mt-5 min-w-[160px] px-10 py-2 bg-green-500 rounded-lg text-white font-bold"
                >
                  OK
                </button> */}
              </>
            ) : (
              <>
                {/* <span className="text-red-500 font-bold">Check Out Failed</span>
                <button
                  onClick={() => handleActionFailed()}
                  className="mt-5 min-w-[160px] px-10 py-2 bg-red-500 rounded-lg text-white font-bold"
                >
                  OK
                </button> */}
              </>
            )}
          </div>
        </>
      )}

      {showQR && (
        <QRScan
          onClose={() => handleCloseScan()}
          onSuccess={(data) => handleSuccess(data)}
          onFailed={() => handleFailed()}
          gate="CHECK OUT"
          event={query.get("event")}
        />
      )}

      <div
        className="w-full min-h-screen  flex flex-col justify-center items-center bg-gray-200 px-0 py-5"
        style={{
          background: `url('${props?.config?.background}') no-repeat center center fixed`,
          backgroundSize: "cover",
        }}
      >
        <div className="flex flex-col justify-center items-center mx-auto bg-white rounded-lg shadow-lg p-5 max-w-3xl w-full">
          <div className="flex justify-center mb-2">
            <LogoApp />
          </div>

          {!loading && state !== "process" && (
            <div className="flex flex-col justify-center items-center mb-10">
              <div className="text-black font-semibold text-sm text-center">
                <div className="font-bold text-xl">Currently Checked Out</div>
                Trinidad: {localReport?.data?.checkout_trinidad - localReport?.data?.reentry_trinidad}
                <br />
                Tobago: {localReport?.data?.checkout_tobago - localReport?.data?.reentry_tobago}
                <br />
                Guest: {localReport?.data?.checkout_guest - localReport?.data?.reentry_guest}
                <br />
              </div>
            </div>
          )}

          {state === "idle" && (
            <div className="flex flex-col justify-center items-center w-full">
              <div className="grid grid-cols-2 gap-5 w-full">
                <div className="w-full">
                  <InputCheckout
                  autofocus={false}
                    onSuccess={(data) => handleSuccessSearch(data)}
                    users={[]}
                    data={selectedData}
                    onClear={() => clearAll()}
                  />
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                  <div className="w-full">
                    <div className=" bg-white rounded-md w-full max-w-lg">
                      <div className="flex flex-col">
                        <div className="text-black mb-2 flex w-full justify-between">
                          <span>Scan QR:</span>
                          <button
                            type="button"
                            onClick={() => setValue("code", "")}
                            className="bg-red-500 text-white px-2 py-0 text-sm rounded-md"
                          >
                            Clear
                          </button>
                        </div>
                        <input
                        autoFocus
                        minLength={10}
                        maxLength={10}
                          placeholder="Scan qr code"
                          className="w-full min-w-[160px] px-2 py-2 bg-whiwhiteet border-2 border-black rounded-lg text-black"
                          {...register("code", {
                            required: true,
                            validate: validateRFID,
                          })}
                        />
                      </div>
                      {errors.code && (
                        <span className="text-red-500 text-sm">
                          QR CODE must consist of 10 characters
                        </span>
                      )}{" "}
                      
                      <button
                        type="submit"
                        className="h-0 w-full bg-blue-500 text-white rounded-md shadow transition duration-300"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              {msgerror !== "" && (
                <div className="w-full max-w-lg text-center py-2 px-2 rounded-md my-2 bg-red-500 text-white font-bold">
                  {msgerror}
                </div>
              )}

              <button
                disabled={checkDisabled()}
                onClick={() => checkDataUser()}
                className={`${
                  checkDisabled() ? "bg-gray-500" : "bg-green-500"
                } mt-4 min-w-[160px] px-10 py-2  rounded-lg text-white font-bold`}
              >
                SUBMIT
              </button>
            </div>
          )}

          {state === "success" && (
            <div className="flex flex-col justify-center items-center pb-0 w-full">
              <div
                className={`${
                  msgCheckin === "Haven't Checked in" ||
                  msgCheckin === "Already Checked Out"
                    ? " bg-red-500"
                    : " bg-green-500"
                } rounded-lg text-white font-bold mb-6 text-xl px-10 py-5 w-full max-w-lg text-center`}
              >
                {msgCheckin}
              </div>

              <div className="text-black flex flex-col font-bold mb-10 mt-8 text-2xl text-center">
                <span className="text-4xl"> {user?.name}</span>
                <span className="text-lg text-black mt-2">
                  {`${
                    user?.dob !== "0000-00-00"
                      ? "(" + moment(user?.dob).format("DD/MM/YYYY") + ")"
                      : ""
                  }`}
                </span>
                <span className="text-lg text-black">{user?.status}</span>
              </div>

              <div className="flex space-x-5">
                {msgCheckin !== "Haven't Checked in" &&
                  msgCheckin !== "Already Checked Out" && (
                    <button
                      onClick={() => handleCheckinOnline()}
                      className="min-w-[160px]  px-10 py-2 bg-green-500 rounded-lg text-white font-bold"
                    >
                      CHECK OUT
                    </button>
                  )}

                {(msgCheckin === "Haven't Checked in" ||
                  msgCheckin === "Already Checked Out") && (
                  <button
                    onClick={() => handleCloseScan()}
                    className=" min-w-[160px] px-10 py-2 bg-red-500 rounded-lg text-white font-bold"
                  >
                    CANCEL
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PageCheckout;
