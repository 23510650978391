// src/PrintCode.js
import React, { useEffect } from "react";
import QRCode from 'react-qr-code'
import { useQuery } from "../../hook/useQuery";

const PrintCode = () => {
  useEffect(() => {
    setTimeout(() => {
     window.close();
    }, 2000);
  }, [])

    const query = useQuery();
  const styles = {
    body: {
      fontFamily: "Arial, sans-serif",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "auto",
      margin: 0,
      background: "white"
    },
    receipt: {
    //   border: "1px solid #000",
      padding: "20px",
      width: "300px",
      textAlign: "center",
    },
    heading: {
      margin: "0px 0 0px",
    },
    headingName: {
        fontSize: '18px',
        margin: "0px 0 10px",
      },
    option: {
      marginTop: "20px",
    },
    img: {
      marginTop: "10px",
      width: "150px",
      height: "150px",
    },
    noPrint: {
      marginTop: "20px",
      textAlign: "center",
    },
    underline:{
        textDecoration: "underline"
    },
    "@media print": {
      noPrint: {
        display: "none",
      },
    },
  };

  return (
    <div style={styles.body}>
      <div style={styles.receipt}>
        <div style={styles.headingName}><strong>{query.get("name") ?? ""}</strong></div>
        <h2 style={styles.heading}><strong>WORKS AGM 2024</strong></h2>
        <p style={styles.heading}>ELECTION VOTING</p>
        <div style={styles.option}>
          <h3 style={styles.underline}>Option 1:</h3>
          <p>
            On Your Mobile Device,
            <br />
            Visit: wcuvote.com
            <br />
            Enter Code Below to Vote
            <br />
            <strong>{query.get("code") ?? ""}</strong>
          </p>
        </div>
        <div style={styles.option}>
          <h3 style={styles.underline}>Option 2:</h3>
          <p>
            Visit a Voting Booth and Scan
            <br />
            QR Code below to Vote
          </p>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "50%", width: "50%", margin: '20px auto 10px' }}
            value={query.get("code") ?? ""}
          />
        </div>
        {/* <div style={styles.noPrint}>
          <button onClick={() => window.print()}>Print PrintCode</button>
        </div> */}
      </div>
    </div>
  );
};

export default PrintCode;
