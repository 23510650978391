import { useEffect, useRef, useState } from "react";
import QRScan from "../../components/qr_scan";
import { useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import { BsFillCheckCircleFill } from "react-icons/bs";
import moment from "moment";

const PageVoting = (props) => {
  const [votingCode, setVotingCode] = useState(null);
  const [state, setState] = useState("validation");
  const [listCandidate, setListCandidate] = useState([]);
  const [idxTab, setIdxTab] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [showQR, setShowQR] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    // if (Cookies.get("token_voting") === undefined) {
    //   navigate("/login-voting");
    // }
    // if (Cookies.get("token_voting") !== undefined || Cookies.get("token_voting") !== null || Cookies.get("token_voting") !== "") {
    //   navigate("/event");
    // }

    getListCandidate();
  }, []);

  useEffect(() => {
    scrollToTop()
  }, [idxTab])

  const scrollToTop = () => {
    if(contentRef.current !== null){
      contentRef.current.scrollTop = 0;
    }
   
  };

  const handleCloseScan = () => {
    setShowQR(false);
  };

  const handleFailed = () => {
    setShowQR(false);
  };

  useEffect(() => {
    if (votingCode == null && state !== "validation") {
      setState("validation");
    }
  }, [votingCode]);

  const navigate = useNavigate();
  const [msg, setMsg] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const getListCandidate = () => {
    axios
      .get(CONFIG.URL + "/vote/candidate", {
        headers: {
          token: CONFIG.TOKEN_VOTING,
        },
      })
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          let arr = response.data?.data?.map((item) => {
            return {
              ...item,
              answer: [],
            };
          });

          setListCandidate(arr);
          setIdxTab(0);
        } else {
        }
      })
      .catch(function (error) {});
  };

  const handleSuccess = (qr) => {
    handleCloseScan();
    onSubmit({
      code: qr,
    });
  };

  const onSubmit = (data) => {
    if (loadingCode) return;

    setLoadingCode(true);
    setMsg("");

    const payload = {
      code: data.code,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/event/qrcode", form_data, {
        headers: {
          token: CONFIG.TOKEN_VOTING,
        },
      })
      .then(function (response) {
        setLoadingCode(false);
        if (response?.data.status === "SUCCESS") {
          if(response.data?.data[0]?.attendance.toLowerCase() === "virtual"){
            if (response.data?.data[0].vote_time !== "0000-00-00 00:00:00") {
              setMsg("You've already voted");
            } else {
              setVotingCode(response?.data.data[0].id_user);
              setState("voting");
            }
          }
          else{
            let event = response.data?.data[0]?.event_gate[0];
            if (event.checkin_time === "0000-00-00 00:00:00") {
              setMsg("Not allowed to vote, please checkin first");
            } else {
              if (response.data?.data[0].vote_time !== "0000-00-00 00:00:00") {
                setMsg("You've already voted");
              } else {
                if(event.checkout_time === "0000-00-00 00:00:00"){
                  setVotingCode(response?.data.data[0].id_user);
                  setState("voting");
                }
                else{
                  if (moment(event.checkin_time) > moment(event.checkout_time)) {
                    setVotingCode(response?.data.data[0].id_user);
                    setState("voting");
                  } 
                  else if(moment(event.reentry_time) > moment(event.checkout_time)){
                    setVotingCode(response?.data.data[0].id_user);
                    setState("voting");
                  }
                  else {
                    setMsg("Error: User is Currently Checked Out. Please Re-Check In at the Entrance");
                  }
                }
              }
            }
          }

          
        } else {
          setMsg(response.data?.message);
        }
      })
      .catch(function (error) {
        setLoadingCode(false);
      });
  };

  const handleAnswer = (data) => {
    let arr = [...listCandidate];
    let idx = arr[idxTab].answer.findIndex(
      (item) => item.id_candidate === data.id_candidate
    );
    if (idx === -1) {
      if (arr[idxTab].answer.length < parseInt(arr[idxTab].max_vote)) {
        arr[idxTab].answer.push(data);
      } else {
        alert(`Only ${arr[idxTab].max_vote} Votes Allowed for This Category`);
      }
    } else {
      arr[idxTab].answer.splice(idx, 1);
    }

    setListCandidate(arr);
  };

  const handleSubmitAnswer = (id_user) => {
    setLoading(true);

    let arr = [];

    listCandidate.map((v) => {
      return v.answer.map((data) => {
        return arr.push({
          id_candidate: data.id_candidate,
        });
      });
    });

    let answer = {
      vote: arr,
    };

    console.log(answer);

    const payload = {
      id_user: votingCode,
      json: JSON.stringify(answer),
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/event/vote", form_data, {
        headers: {
          token: CONFIG.TOKEN_VOTING,
        },
      })
      .then(function (response) {
        setLoading(false);
        if (response?.data.status === "SUCCESS") {
          setState("finish");
          setTimeout(() => {
            reloadPage();
          }, 1000 * 5);
        } else {
          setMsg("Error: " + response.data?.message);
        }
      })
      .catch(function (error) {});
  };

  const reloadPage = () => {
    window.location.reload(true);
  };

  const checkActive = (item) => {
    let active = listCandidate[idxTab].answer?.find(
      (v) => v.id_candidate === item.id_candidate
    );
    if (active) return true;
    else return false;
  };

  if (props?.config?.vote_status === "disable") {
    return (
      <>
        <div
          className="w-full h-screen flex flex-col justify-center items-center bg-gray-200 px-5"
          style={{
            background: `url('${props?.config?.background_voting}') no-repeat center center fixed`,
            backgroundSize: "cover",
          }}
        >
          <div className="flex flex-col justify-center items-center w-full">
            <div className="flex flex-col justify-center items-center space-y-2.5 w-full">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-5 px-5 py-10 bg-white rounded-md w-full max-w-lg items-center flex flex-col"
              >
                <div className="text-center font-bold text-2xl ">
                  {props?.config?.title_voting}
                </div>

                <div className="text-center font-bold text-xl rounded-md  bg-red-500 px-5 py-2 text-white">
                  {props?.config?.text_info_voting}
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (
    (listCandidate === null || listCandidate?.length === 0) &&
    props?.config?.vote_status === "enable"
  ) {
    return (
      <div className="flex flex-col absolute top-0 left-0 w-screen min-h-screen bg-[#000000b3] text-white justify-center items-center font-semibold text-xl">
        Loading...
      </div>
    );
  }

  return (
    <>
      {showQR && (
        <QRScan
          onClose={() => handleCloseScan()}
          onSuccess={(data) => handleSuccess(data)}
          onFailed={() => handleFailed()}
          gate="CHECK IN"
          event={""}
        />
      )}
      <div
        className="w-full h-screen flex flex-col justify-center items-center bg-gray-200 px-5"
        style={{
          background: `url('${props?.config?.background_voting}') no-repeat center center fixed`,
          backgroundSize: "cover",
        }}
      >
        {loading && (
          <>
            <div className="flex z-50 flex-col absolute top-0 left-0 w-screen min-h-screen bg-[#000000b3] text-white justify-center items-center font-semibold text-xl">
              Loading...
            </div>
          </>
        )}

        <div className="flex flex-col justify-center items-center w-full">
          <div className="flex flex-col justify-center items-center space-y-2.5 w-full">
            {/* STATE FINISH */}
            {state === "finish" && (
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-5 px-5 py-5 bg-white rounded-md w-full max-w-lg items-center flex flex-col"
              >
                <div className="text-center font-bold text-2xl ">
                  {props?.config?.title_voting}
                </div>

                <div className="text-center font-bold   ">
                  {props?.config?.text_success_voting}
                </div>

                <button
                  type="button"
                  onClick={() => reloadPage()}
                  className={`w-max min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500 mx-auto text-center`}
                >
                  OK
                </button>
              </form>
            )}

            {/* STATE VOTING */}
            {state === "voting" && (
              <div id="content" ref={contentRef} className="px-2  md:px-5 py-5 bg-white rounded-md w-full max-w-7xl h-full max-h-[95vh] overflow-y-auto">
                <div className="text-center font-bold mb-10  text-2xl ">
                  {props?.config?.title_voting}
                </div>

                <div className="overflow-x-scroll md:overflow-x-auto w-full">
                  <div className="flex items-center w-full md:justify-around">
                    {listCandidate?.map((item, key) => {
                      return (
                        <div className="flex flex-col items-center space-y-2 px-5">
                          <button
                            onClick={
                              item.answer?.length !== 0
                                ? () => setIdxTab(key)
                                : () => {}
                            }
                            key={key}
                            type="button"
                            className={`${
                              idxTab === key
                                ? "bg-green-500"
                                : item.answer?.length !== 0
                                ? "bg-green-500"
                                : "bg-gray-500"
                            } w-8 h-8 md:w-10 md:h-10  rounded-full text-white font-bold   text-center`}
                          >
                            {key + 1}
                          </button>
                          <div className="whitespace-nowrap">
                            Step {key + 1}
                          </div>
                        </div>
                      );
                    })}

                    <div className="flex flex-col items-center space-y-2 px-5">
                      <button
                        onClick={
                          listCandidate.find(
                            (item) => item.answer?.length !== 0
                          ) === undefined
                            ? () => setIdxTab(listCandidate.length)
                            : () => {}
                        }
                        type="button"
                        className={`${
                          idxTab === listCandidate.length
                            ? "bg-green-500"
                            : listCandidate.length > 0 &&
                              idxTab === listCandidate.length &&
                              listCandidate[listCandidate.length - 1].answer
                                ?.length !== 0
                            ? "bg-green-500"
                            : "bg-gray-500"
                        } w-8 h-8 md:w-10 md:h-10  rounded-full text-white font-bold   text-center`}
                      >
                        {listCandidate.length + 1}
                      </button>
                      <div>Preview </div>
                    </div>
                  </div>
                </div>

                <hr className="my-5" />

                {idxTab !== listCandidate.length && (
                  <>
                    <h2 className="my-5 text-xl w-full flex justify-center items-center font-bold mb-0">
                      {listCandidate[idxTab]?.category}
                    </h2>
                    <span className="mb-5 mt-1 w-full flex justify-center items-center">
                      Total Choices {listCandidate[idxTab]?.answer?.length} of{" "}
                      {listCandidate[idxTab]?.max_vote}
                    </span>
                    <div className="flex flex-wrap justify-center items-start w-full pb-2">
                      {listCandidate
                        .find(
                          (v) =>
                            v.id_category === listCandidate[idxTab].id_category
                        )
                        ?.candidate.map((item, key) => {
                          return (
                            <button
                              key={key}
                              onClick={() => handleAnswer(item)}
                              className={`${
                                checkActive(item)
                                  ? "border-green-500"
                                  : "border-transparent"
                              } relative ${
                                listCandidate[idxTab]?.answer?.length ===
                                parseInt(listCandidate[idxTab]?.max_vote)
                                  ? ""
                                  : "md:hover:border-green-500"
                              } flex flex-col justify-start items-center border-[4px] w-32 md:w-40 h-full mx-2 mb-2`}
                            >
                              <img
                                className={`w-32 md:w-40 h-32 md:h-40 font-bold object-cover bg-white ${
                                  checkActive(item)
                                    ? "grayscale"
                                    : "grayscale-0"
                                } `}
                                src={item.photo}
                                alt=""
                              />
                              <div className="flex h-full py-2 flex-grow border w-full text-center justify-center items-center">
                                {item.name}
                              </div>
                            </button>
                          );
                        })}
                    </div>
                  </>
                )}

                {idxTab === listCandidate.length && (
                  <div className="w-full py-2 items-center flex flex-col">
                    <h2 className="text-xl font-bold mb-4">Voting Summary</h2>

                    <div className="flex flex-col justify-start items-center w-full py-2">
                      {listCandidate?.map((item, key) => {
                        return (
                          <div key={key}>
                            <h2 className="text-2xl font-bold mb-2 w-full text-center justify-center items-center">
                              {item?.category}
                            </h2>
                            <div className="flex flex-wrap justify-center items-start w-full py-2 mb-4">
                              {item.answer.map((answer, subkey) => {
                                return (
                                  <div
                                    key={subkey}
                                    className={`flex flex-col justify-start items-center border w-32 md:w-40 h-full mx-2 mb-2`}
                                  >
                                    <img
                                      className="w-32 md:w-40 h-32 md:h-40 font-bold object-cover bg-white"
                                      src={answer.photo}
                                      alt=""
                                    />
                                    <div className="flex h-full py-2 flex-grow border w-full text-center justify-center items-center">
                                      {answer.name}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                <div className="flex flex-wrap justify-between items-center w-full">
                  {idxTab > 0 ? (
                    <button
                      type="button"
                      onClick={() => setIdxTab(idxTab - 1)}
                      className={`min-w-[140px] px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-max text-center`}
                    >
                      Back
                    </button>
                  ) : (
                    <div></div>
                  )}

                  {idxTab < listCandidate.length ? (
                    <button
                      type="button"
                      onClick={
                        listCandidate[idxTab]?.answer.length > 0
                          ? () => setIdxTab(idxTab + 1)
                          : () => {}
                      }
                      className={`${
                        listCandidate[idxTab]?.answer.length > 0
                          ? "bg-blue-500"
                          : "bg-gray-500"
                      } min-w-[140px] px-10 py-2 rounded-lg text-white font-bold w-max text-center`}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => handleSubmitAnswer()}
                      className={`bg-green-500 min-w-[140px] px-10 py-2 rounded-lg text-white font-bold w-max text-center`}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            )}

            {/* STATE VALIDATION */}
            {state === "validation" && (
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-5 px-5 py-5 bg-white rounded-md w-full max-w-lg"
              >
                <div className="text-center font-bold mb-5  text-2xl ">
                  {props?.config?.title_voting}
                </div>

                <div className="text-center font-bold mb-5   ">
                  Please Enter Your Voting Code Below
                </div>

                <div className="flex flex-col">
                  <input
                    autoFocus
                    className="border-2 px-2 py-2 rounded-sm"
                    {...register("code", { required: true })}
                  />
                  {errors.code && (
                    <span className="text-red-500 text-sm">
                      This code is required
                    </span>
                  )}
                </div>

                <div className="text-center font-bold text-red-500">{msg}</div>

                <div className="grid md:grid-cols-1 gap-3">
                  {/* <button
                    type="button"
                    onClick={() => setShowQR(true)}
                    className={`md:min-w-[160px] whitespace-nowrap px-2 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
                  >
                    Mobile Scan
                  </button> */}
                  <button
                    type="submit"
                    className={`min-w-[160px]  whitespace-nowrap  px-10 py-2 rounded-lg text-white font-bold bg-blue-500  w-full text-center`}
                  >
                    {loadingCode ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PageVoting;
